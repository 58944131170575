// import React from 'react';
import {
  Button,
  Grid,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import SinglePushModal from "./Push-modal";
import { PushDeleteModal } from "./PushDeleteModal";
import { getFutureUserPremium } from "services/mobile-app.service";

const example = [
  {
    id: 1,
    email: "example@1com",
    time: 1,
    used: true,
  },
  {
    id: 2,
    email: "example2@2com",
    time: 10,
    used: true,
  },
  {
    id: 3,
    email: "example2@2com",
    time: 20,
    used: true,
  },
  {
    id: 4,
    email: "example3@3com",
    time: 30,
    used: true,
  },
];

function PushEvents() {
  const [modal, setModal] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [toEdit, setToEdit] = useState({});
  const [users, setUsers] = useState<any[]>([]);
  const columns = [
    // {
    //   field: "id",
    //   headerName: "id",
    //   editable: false,
    //   width: 10,
    // },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      editable: false,
    },
    {
      field: "time",
      headerName: "Tiempo",
      flex: 1,
      editable: false,
    },
    {
      field: "used",
      headerName: "Usado",
      flex: 2,
      editable: false,
    },
    {
      field: "delete",
      headerName: "Borrar",
      // flex:1,
      width: 140,
      editable: false,
      renderCell: (tmpt: any) => {
        return (
          <Grid display={"flex"} alignItems={"center"}>
            <button
              type="button"
              className="btn btn-danger btn-sm ml-2"
              onClick={(el) => {
                setToEdit(tmpt.row);
                setModalDelete(true);
              }}
            >
              <i className="fa fa-eraser"></i>
            </button>
          </Grid>
        );
      },
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  useEffect(() => {
    const getAllPrem = async () => {
        let userPremium = await getFutureUserPremium();
        let temp = userPremium.data.subscriptions.map((item:any,inx:number)=>{return {...item,id:inx}})
        if(userPremium.data.ok) setUsers(temp);
    }
    getAllPrem()
  }, []);

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          onClick={() => {
            setToEdit({ reset: true });
            setModal(true);
          }}
        >
          Agregarle premium a un usuario futuro.
        </Button>
      </div>
      <DataGrid
        rows={users.length!=0?users:example}
        //@ts-ignore
        columns={columns}
        getRowHeight={() => "auto"}
        autoHeight
        initialState={{
          sorting: {
            sortModel: [{ field: "date_to_send", sort: "asc" }],
          },
        }}
        // autosizeOptions={{
        //   columns: ['delete'],
        //   includeOutliers: true,
        //   includeHeaders: false,
        // }}
      />
      <SinglePushModal
        visible={modal}
        close={() => {
          setModal(false);
        }}
      />
      <PushDeleteModal
        visible={modalDelete}
        close={setModalDelete}
        pushToDelete={toEdit}
      />
    </div>
  );
}


export default PushEvents;
